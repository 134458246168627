@use 'variables';
$gl-blue: variables.$gl-blue;
$gl-coral: variables.$gl-coral;
$gl-green: variables.$gl-green;
$gl-gray: variables.$gl-gray;
$gl-green-dark: variables.$gl-green-dark;
@font-face
{
	font-family: 'Open Sans Serifed-I';
	font-style: normal;
	font-weight: 400;
	src: url(../content/fonts/regular--regular.ttf) format('truetype');
	unicode-range: U+49;
}

@font-face
{
	font-family: 'Open Sans Readable';
	font-style: normal;
	font-weight: 700;
	src: url(../content/fonts/readable.ttf) format('truetype');
}


html
{
	overflow-y: scroll;
	overflow-x: hidden;
	font-size: 16px;
}


body
{
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: 400;
}

#page
{
	/* Add margin at top for fixed (out-of-flow) navbar */
	margin-top: 85px !important;
	min-height: calc(100vh - 180px)
}

#page, .axis text
{
	font-size: 16px;
}

@media only print
{
	/* Undo navbar margin for printing (navbar is hidden for print media) */
	#page
	{
		margin-top: 0px;
	}

	/* Avoid page breaks inside sections of legal pages (EULA, Privavy Policy, etc.) */
	.legal-block div
	{
		page-break-inside: avoid;
	}

	a[href].no-print-url:after
	{
		opacity: 0;
	}

	a[href].btn-lg:not(.no-print-url):after
	{
		font-size: 1px;
	}

	.btn-secondary.active
	{
		font-weight: 700;
	}

	.btn-secondary.inactive
	{
		font-weight: 300;
	}
}

@media only screen and (min-width: 992px) {

	#navbar-collapse {
		margin-right: 2px;
		margin-left : 2px;
	}
	/* add padding between navbar items */
	.navbar-nav > li {
		padding-left:10px;
		padding-right:10px;
	}
}

/* Increase navbar height by 30px to fit image */
.navbar
{
	min-height: 75px;
	font-size: 14px;
	background-color: white;
	box-shadow: 0 0 12px 1px $gl-gray;
}

/* Bring the collapsed navbar's top line down to fit image */
/* Bring navbar contents down to adjust for taller navbar */
/* Navbar was expanded by 30px to accomodate image, so links are brought down by half that value to maintain centering */
.collapse.navbar-collapse.in, .collapsing.navbar-collapse, nav .container-fluid
{
	margin-top: 15px;
}

/* Make background slightly transparent */
.navbar.navbar-default.navbar-fixed-top
{
	background-color: rgba(244, 244, 244, 1);
}


/* Image needs to be fixed-size for absolute centering to work */
.navbar-brand img
{
	width: 70px;
	height: 70px;
}


/* Override default styling which puts edge of navbar off right edge of screen */
/* Undo left button separation when in collapsed navbar */
.navbar-right, .collapse.navbar-collapse.in .navbar-left .navbar-btn, .collapsing.navbar-collapse .navbar-left .navbar-btn
{
	margin-right: 0px;
}


/* Separate left button(s) from other navbar links */
.navbar-left .navbar-btn
{
	margin-right: 20px;
}

/* Separate right button(s) from other navbar links */
.navbar-right .navbar-btn
{
	margin-left: 20px;
}

/* Undo right button separation when in collapsed navbar */
.collapse.navbar-collapse.in .navbar-right .navbar-btn, .collapsing.navbar-collapse .navbar-right .navbar-btn
{
	margin-left: 0px;
}

@media only screen and (max-width: 767px)
{
	#page
	{
		margin-top: 60px;
	}

	.navbar
	{
		min-height: 60px;
	}

	/* Image needs to be fixed-size for absolute centering to work */
	.navbar-brand img
	{
		width: 50px;
		height: 50px;
	}

	nav .container-fluid
	{
		margin-top: 5px;
	}

	.collapse.navbar-collapse.in, .collapsing.navbar-collapse
	{
		margin-top: 5px;
	}
}


.section-header, hr.section-divider
{
	/* Remove selection cursor for page headers */
	cursor: default;

	/* Place divider line under heading */
	border-bottom-width: 2px;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 85, 187,0.5);
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	padding-bottom: 15px;
	margin-bottom: 15px;
}
.section-divider
{
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

/* Light font weight for largest headings/buttons */
.h1, .h2, .h3 {
	color: #181145;
}

h1, .btn-xl
{
	font-weight: 300;
}
.flex-1 {
	flex-grow: 1;
	flex-shrink:1;
	flex-basis:0;
}
.modal-content {
	border-radius: 0.6rem;
}

/* Central definition for transitions, to allow for a consistent aesthetic */
a, button, .btn.btn-secondary, .response, .response-text, .btn .button-arrow .line, .carousel-indicators li, .carousel-control
{
	transition-duration: 0.1s;
}

a {
	color: $gl-blue;
}
a:hover
{
	color: #333333;
	text-decoration: none;
}
/* Remove link effects */
a.link-unstyled, .card> a, a.information
{
	color: inherit;
	text-decoration: inherit;
}



/* Set active styling on mouse down or when labeled with "active" class */
/* Do not change active state when mouse down on an "active"-class button */
.btn-secondary:active:not(.active), .btn-secondary.active, .active > .btn-secondary
{
	box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.175) inset;
}


/* New extra-large button (bootstrap's max is large/"lg") */
.btn-xl
{
	margin-top: 10px;
	margin-bottom: 10px;

	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 20px;
	padding-right: 20px;

	border-radius: 8px;

	font-size: 22px;
}


/* Add extra spacing to bootstrap-provided buttons */
.btn-lg
{
	margin-top: 7.5px;
	margin-bottom: 7.5px;
}

.btn-md
{
	margin-top: 10px;
	margin-bottom: 10px;
}

.button-inline
{
	display: inline;

	margin-left: 10px;
	margin-right: 5px;

	padding-right: 15px;
	padding-left: 15px;
}

.action-link
{
	text-decoration: none;
	vertical-align: middle;
	margin-left: 10px;
	margin-right: 10px;
}

.action-link:last-of-type
{
	margin-right: 20px;
}

/* Change size and position of fas when used in buttons */
.btn .fas
{
	font-size: 15px;

	left: 10px;
}

.button-arrow
{
	margin-bottom: -0.2em;
	margin-left: -0.5em;
	margin-right: 0.5em;
}

.btn .button-arrow .line
{
	stroke: #333333;
	fill: none;

	transition-property: fill, stroke;
}

.btn .button-arrow .line
{
	stroke-width: 3px;

	stroke-linecap: round;
	stroke-linejoin: round;
}

.btn:hover .button-arrow .line
{
	stroke: #FFFFFF;
}


.mb-3 label
{
	transition-property: color;
}

.mb-3 input, .mb-3 select, .mb-3 textarea
{
	transition-property: border-color;
}

.mb-3 .input-group-text
{
	transition-property: color, background-color, border-color;
}

.input-group-text.checkbox label
{
	min-height: initial;
}

.list-group-item {
	text-align: center;
}

.list-group-item.active, .list-group-item.active:hover
{
	background-color: #749ED1;
	border-color: #749ED1;
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text
{
	color: #FFFFFF;
}

.graph-point-data
	{
	position: absolute;
	text-align: center;
	width: 80px;
	height: 47px;
	padding: 2px;
	font: 12px sans-serif;
	background: #0055BB;
	color: white;
	border: 0px;
	border-radius: 8px;
	pointer-events: none;
}

p > :last-child {
	border-top-right-radius: 0.2rem;
}

hr.strong
{
	border-top-width: 2px;
}

hr.inline
{
	display: inline-block;
	width: 150px;

	position: relative;
	top: 1em;
}

hr.inline + p
{
	display: inline-block;
}

hr.inline:first-of-type
{
	margin-right: 1em;
}

hr.inline:last-of-type
{
	margin-left: 1em;
}


/* Central definition for "bold" font weight */
b, strong, .bold
{
	font-weight: 600 !important;
}

/* Add justified text class, mirroring bootstrap's similar classes */
.text-justify
{
	text-align: justify;
}


.float-right-absolute
{
	position: absolute;

	right: 0;
}

@media only screen and (max-width: 479px)
{
	html {
		font-size: 14px;
	}
	.col-xxs-12
	{
		float: left;

		width: 100%;
	}

	.col-xxs-6
	{
		float: left;
		width: 50%;
	}

	.col-xxs-offset-0
	{
		margin-left: 0;
	}

	.btn-xl.long-text
	{
		font-size: 16px;
	}
}

@media only screen and (max-width: 319px)
{
	.col-xxxs-12
	{
		float: left;

		width: 100%;
	}

	.col-xxxs-offset-0
	{
		margin-left: 0;
	}

	.btn-xl.long-text
	{
		font-size: 14px;
	}
}

@media only screen and (min-width: 1200px)
{
	.offset-lg-1_5
	{
		margin-left: 12.5%;
	}
}


@media only screen and (max-width: 767px)
{
	.text-center-xs
	{
		text-align: center;
	}

	.form-horizontal label.bold-xs
	{
		font-weight: 600;
	}


	.information
	{
		display: block;

		margin: 15px;
	}


	.help-container, input + * .animation-container, select + * .animation-container, textarea + * .animation-container
	{
		transition-delay: 0.2s;
	}
}


@media only screen and (min-width: 768px)
{
	.float-right-sm
	{
		float: right !important;
	}

	.float-left-sm
	{
		float: left !important;
	}


	.information-cell
	{
		float: none;
		display: table-cell;

		vertical-align: middle;

		border: 15px solid white;
	}
}

/* Override bootstrap's default alert text alignment */
.alert
{
	text-align: center;
}

.alert:only-child
{
	margin-bottom: 15px;
}


/* Central definition for bottom margins, to allow for a consistent aesthetic */
.subheader-text, .carousel.indicators-bottom {
	text-align: center;
	margin-bottom: 25px;
}


/* Central definition for top margins, to allow for a consistent aesthetic */
.prefooter-text, address, .alert
{
	margin-top: 4px;
}


.information
{
	padding: 10px;

	text-align: center;
	font-size: 18px;
}

/* Rollover shadows for information boxes & Facebook/Twitter icons */
.information, .social-links a img
{
	transition-property: box-shadow;
}

.information, .social-links a img, .card-collapse.collapsing, .subscribe-submit .mb-3, .mb-3 label, .mb-3 input, .mb-3 select, .mb-3 textarea, .mb-3 .input-group-text, .animation-container, .help-container
{
	transition-duration: 0.2s;
}

.information:hover
{
	box-shadow:	 0px  1px 1px rgba(160, 160, 160, 0.5) inset,
				 0px -1px 1px rgba(160, 160, 160, 0.5) inset,
				-1px  0px 1px rgba(160, 160, 160, 0.5) inset,
				 1px  0px 1px rgba(160, 160, 160, 0.5) inset;
}

.information-block
{
	display: block;

	margin-top: 15px;
	margin-bottom: 15px;
}


/* Undo default bolding of labels in forms */
.form-horizontal label
{
	font-weight: inherit;
}
.graph-point-data
	{
	position: absolute;
	text-align: center;
	width: 80px;
	height: 47px;
	padding: 2px;
	font: 12px sans-serif;
	background: #0055BB;
	color: white;
	border: 0px;
	border-radius: 8px;
	pointer-events: none;
}

p > :last-child {
	border-top-right-radius: 0.2rem;
}
/* content-block is only affecting GL Common components - 07/08/2022 */
.content-block, .content-block.medium, .content-block.small {
	margin: auto;
}
.content-block {
	max-width: 1150px;
}
.content-block.extra-small {
	max-width: 738px;
}

@media only screen and (max-width: 767px)
{
	.content-block.small
	{
		max-width: 450px;
	}
}

.response-text, .serifed-i {
	font-family: 'Open Sans Serifed-I', 'Open Sans', Arial, sans-serif;
}

.readable {
	font-family: 'Open Sans Readable', 'Open Sans', Arial, sans-serif;
	font-weight: 700;
	letter-spacing: 0.1em;
}

footer, .social-links {
	margin-bottom: 1em;
}
.social-links a:hover img {
	box-shadow:	 0px  1px 1px rgba(160, 160, 160, 0.5),
				 0px -1px 1px rgba(160, 160, 160, 0.5),
				-1px  0px 1px rgba(160, 160, 160, 0.5),
				 1px  0px 1px rgba(160, 160, 160, 0.5);
}
.social-links img {
	width: 25px;
	height: 25px;

	border-radius: 3px;
}
.social-links img.float-left {
	margin-right: 5px;
}

.social-links img.float-right {
	margin-left: 5px;
}

.break-words
{
	word-wrap: break-word;
}

.action-list
{
	overflow: hidden;
}

.action-list input[type=checkbox], input[type=checkbox].checkbox-shortcut
{
	margin-right: 5px;
}

input[type=checkbox].checkbox-shortcut
{
	margin-left: 15px;
}

.action-list label
{
	margin-bottom: 0;
}


/* Hide random-start carousels on load */
/* Carousel will be faded in by JavaScript after a random starting slide has been chosen */
.carousel.random
{
	opacity: 0;
}

/* Matching rounded corners on carousel inner content, captions, and control buttons */
.carousel-inner.rounded, .carousel-caption, .carousel-control.rounded
{
	border-radius: 10px;
}

.carousel-caption
{
	padding-top: 15px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 35px;

	margin-top: 15px;

	background-color: rgba(0, 0, 0, 0.85);

	position: relative;
	left: 0px;
	bottom: 0px;
}

.carousel-control
{
	width: 10%;

	transition-property: opacity;
}


/* .carousel-indicators
{
	width: 100%;

	margin-left: -50%;
} */

/* Carousel with indicators fixed above content */
.carousel-indicators.indicators-top
{
	top: -30px;
	bottom: initial;
}

.carousel.indicators-top
{
	margin-top: 40px;
}

/* Carousel with indicators below content, moving if content height changes */
.carousel-indicators.indicators-bottom
{
	top: 45%;
}

/* Indicators of current carousel slide */
.carousel-indicators li
{
	border-style: solid;
	border-color: #000000;
	border-width: 2px;
}

.carousel-indicators li
{
	background-color: rgba(0, 0, 0, 0.1);

	transition-property: background-color, height, width, margin-top, margin-left, margin-right;
}

.carousel-indicators .active
{
	background-color: #FFFFFF;
}

.carousel-indicators li
{
	margin-right: 5px;
	margin-left: 5px;
}

/* Decrease margin when an indicator is active so that the indicators don't move with a slide change */
.carousel-indicators li.active
{
	margin-right: 4px;
	margin-left: 4px;
}

/* Home page carousel images are only 1600 x 800 */
.carousel#home-carousel
{
	max-width: 1600px;
}


.control-button .line, .control-button .rectangle
{
	stroke: #A0A0A0;

	transition-duration: 0.1s;
	transition-timing-function: linear;
	transition-property: fill, stroke;
}

.control-button .line
{
	stroke-width: 2px;

	stroke-linecap: round;
	stroke-linejoin: round;
}

.control-button:hover .line
{
	stroke: #FFFFFF;
}

.control-button .rectangle
{
	stroke-width: 1.5px;
	fill: #FFFFFF;
}

.control-button:hover .rectangle
{
	fill: #507BAE;
}

.goal {
	stroke: $gl-green;
}

.line
{
	stroke-width: 1.5px;
}

.line:not(.filled)
{
	fill: none;
}

.point
{
	stroke: none;
}

.line.dashed
{
	stroke-dasharray: 5, 5;
	shape-rendering: crispEdges;
}

.line.sunday
{
	fill: none;
	stroke: #000000;
	stroke-width: 0.5px;
	shape-rendering: auto;
}

.line.light
{
	fill: none;
	stroke: #000000;
	stroke-width: 2px;
	shape-rendering: auto;

	opacity: 0.3;
}

.line.goal
{
	stroke-width: 2px;
}

.axis path, .axis line
{
	fill: none;
	stroke: #000000;
	shape-rendering: crispEdges;
}


.safari-username-autofill
{
	position: absolute;
	top: -100px;

	display: none;
}

.state-autofill-target
{
	height: 0px !important;
	overflow: hidden;

	margin: 0;
}


.hidden-horizontal-overflow
{
	overflow-x: hidden;
}

.subscribe-submit
{
	position: relative;
}

.subscribe-submit .mb-3
{
	transition-property: opacity;
}

.subscribe-submit .absolute
{
	position: absolute;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	margin: auto;
}

.subscribe-submit .absolute div
{
	display: inline-block;
	vertical-align: middle;
}

.subscribe-submit .absolute div.vertical-align-dummy
{
	height: 100%;
}

.spacing-line
{
	height: 1em;
}


.image-list li, .image-list-break
{
	margin-bottom: 10px;
}

.image-list-image
{
	margin-left: 25px;
	margin-right: 25px;

	width: 600px;
	max-width: 50%;
}

.image-list-break
{
	max-width: 75%
}

.show-on-load
{
	display: none;
}


.text-center table th
{
	text-align: center;
}


.redirect-container:first-child
{
	margin-top: 20px;
}

.nopadding
{
	padding: 0px !important;
}

/* Specific for the extend page */
.extend-p
{
	margin: 10px;
}

.extend-p i
{
	float: right;
}

.accordion-toggle.collapsed .extend-p i::after
{
	content: "\f077"; /* fa-chevron-up (hex value) */
}

.accordion-toggle .extend-p i::after
{
	content: "\f078"; /* fa-chevron-down (hex value) */
}
.story-slogan
{
	line-height: 1.5em;
	font-size: 1.3em;
}
.zoom
{
	transition: transform .2s; /* Animation */
	position: relative;
	z-index: 10;
	cursor: zoom-in;
}

.zoom:active
{
	transform: scale(2);
	cursor: grabbing;
}


/* Fixed/sticky icon bar */
.social-networking-bar
{
	position: fixed;
	top: 60%;
	left: 0%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.social-networking-bar a
{
	display: block;
	margin-left: 10px;
	margin-top: 4px;
	margin-bottom: 4px;
	margin-right: 0px;
}
@media print
{
	body > main > *,
	nav, nav *,
	footer, footer *,
	.social-networking-bar, .social-networking-bar *
	{
		display: none;
	}
	
	#printable-charts, #printable-charts *,
	#donut-charts
	{
		display: block !important;
	}
	
	#raw-records,
	#donut-charts
	{
		display: inline !important;
	}
	
	a[href]:after,
	#probe-nav,
	.dont-print {
		display: none;
		visibility: hidden;
	}
	.print-only {
		display: inherit;
	}
}
.share-title
{
	padding: 5px;
	padding-left: 25px;
	padding-right: 25px;
	border-style: solid;
	border-color: #0055BB;
	border-width: 3px;
	border-radius: 15px;
}

.share-title-container
{
	margin-top: 90px;
	margin-bottom: 20px;
}

.carousel-controls-logged-in
{
	position:relative;
	width:300px;
	margin:0 auto;
	margin-top: 25px;
	font-size: 30px;
}

.carousel-indicators-logged-in
{
	top:0px;
}

.video-arrow
{
	color: grey;
}

.video-arrow:hover
{
	color: #A9A9A9;
}

#progress-message
{
	padding-top: 135px;
	padding-bottom: 10px;
}

.vcenter
{
	display: inline-block;
	vertical-align: middle;
	float: none;
}

.privacy-banner
{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	padding: 1em .5rem;
	background: #fff;
	z-index: 1030;
	color: #000;
	font-size: 14px;
	margin: 0;
	border-top: 2px solid #0055BB;
}

.privacy-banner button
{
	position: absolute;
	right: 5px;
	top: calc(50% - 12.5px);
	color: #fff;
	outline: 0;
	height: 25px;
	width: 25px;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.35em;
	font-weight: 700;
	border-radius: 50%;
	text-align: center;
	padding: 0;
	line-height: 1;
	background: #000;
	cursor: pointer;
	background: #0055BB;
}

h1 .no-header
{
	border-bottom-width: 0px;
	border-bottom-style: solid;
	border-bottom-color: #0055BB;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

.bottom-social-links
{
	margin-right: 17px;
}

.blue-border
{
	border-color: #0055BB;
}
.gl-blue {
	color: $gl-blue;
}
input:focus,
body:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}


.has-warning {
	border-color: #ffcc00;
	padding-right: calc(1.5em + .75rem);
	background-repeat: no-repeat;
	background-position: right calc(.375em + .1875rem) center;
	background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
/* react bootstrap dropdown goes off screen since its on right side on desktop view
   and is mis aligned on mobile, so these fix both issues
*/

.account-dropdown {
	.dropdown-menu {
		width: 250px;
		overflow: auto;
	}
	@media (max-width: 992px) {
			text-align: left !important;
			margin: 0 0 0.5rem 0.5rem !important;
	}
	@media (min-width: 992px) {

		.dropdown-menu {
			right: 0;
			max-height: calc(100vh - 326px); /* 260px is the height of the expanded navbar */
		}
	}
}
@media (max-width: 992px) {
	.navbar-collapse {
		overflow: auto;
		max-height: 70vh;
	}
}
@media (min-width: 992px) {
	.ms-half-navbar {
		margin-left: calc(50% - 19.8rem); /* center on page, cant use other methods due to react-bootstrap strict element hierarchy */
	}
}
.circle-icon
{
	display: inline-block;

	border-radius: 50%;
	padding: 8px;
	margin: 0px;

	background: #666;
	color: #fff;
	text-align: center;
	line-height: 24px;
	width: 38px;
	height: 38px;
}

.circle-icon.single {
    padding-left: 14px;
    padding-right: 14px;
}

.circle-icon.droplist {
    margin-right: 12px;
}

.with-icon {
	padding-top: .5em;
	padding-bottom: .5em;
}
.with-icon:hover {
	background-color: #0333f323 !important;
}

.with-caret {
	cursor: pointer;
}
.with-caret::after {
	cursor: pointer;
}
.dropdown-item.with-icon {
	padding-top: .25em;
	padding-bottom: .25em;
}

.dropdown-item.with-caret::after {
	display: inline-block;
	margin-left: .255em;
	vertical-align: .255em;
	content: "";
	border-top: .3em solid;
	border-right: .3em solid transparent;
	border-bottom: 0;
	border-left: .3em solid transparent;
	cursor: pointer;
}

#group-list
{
	max-height: 20vh;
	overflow-y: auto;
}

.nav-link
{
	font-size: 16px;
	font-weight: 600;
}

/* manage table */

/* Add no padding when modal is open for the body. The modal applies some un-needed css when a modal is opened that this disables. */
body.modal-open {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.tooltip-enabled > div.tooltip-inner  {
	color: #333333;
	border: solid 1px $gl-blue;
	background: rgba(254, 254, 254);
	border-radius: 6px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	padding:8px;
}

/* Disabled button's tooltip */
.tooltip-disabled {
	color: gray !important;
	border-color: gray !important;
}
.tooltip-disabled > div.tooltip-inner  {
	color: #333333;
	border: solid 1px rgba(0, 85, 187,0.6);
	background: rgba(254, 254, 254, 0.4);
	border-radius: 6px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	padding:8px;
}
.arrow::before {
	border-top-color: rgba(0, 85, 187,0.6) !important;
	margin-bottom: 4px;
}
// Bootstrap button theme overrides
.btn-secondary
{
	transition-property: color, background-color, box-shadow;
	background-color: #fff;
	border-color: #ccc;
	color: #333;
}
.btn-secondary:hover:not(.active), .btn-secondary.active, .btn-secondary.active:hover, .active > .btn-secondary, .card.active .card-header, .btn-secondary:focus
{
	color: #FFFFFF;
	background-color: #507BAE !important;
}

.btn-secondary.alternate-fill:hover:not(.active), .btn-secondary.alternate-fill.active, .btn-secondary.alternate-fill.active:hover, .active > .btn-secondary.alternate-fill
{
	background-color: #749ED1;
}
@mixin gl-btn($color,$bg: rgba($color,0.85), $font-color: white) {
	font-size: 1.15rem;
	color: $font-color;
	background-color: $bg;
	border-color: $color;
	border-bottom: 4px solid $color;
	z-index: 1;
	&:hover {
			border-bottom: 3px solid $color;
			border-color: $color;
			background-color: rgba($bg,0.87);
			color: $font-color;
	}
	&:focus {
			background-color: rgba($bg,0.87);
			border-bottom: 1px solid $color;
	}
	&:active {
		background-color: $bg;
		border-bottom: 1px solid $color;
		color: white !important;
}
}
.btn-primary {
	@include gl-btn($gl-blue);
}
.btn-secondary-outline {
	@include gl-btn($gl-gray,#fff,#555);
}
.btn-outline-success {
	@include gl-btn($gl-green,#fff,$gl-green-dark);
}
.btn-danger {
	@include gl-btn(#cf2317, $gl-coral);
}
.btn-success {
	@include gl-btn($gl-green-dark,$gl-green);
}
.btn.oval { // make bootstrap btn oval shaped
	padding-right: 4rem;
	padding-left: 4rem;
	border-radius: 1.5rem;
}
.close { // bootstrap 5 for unknown reason messed up modal close button
	background-color: transparent;
	border: none;
	font-size: 27px;
	color: $gl-gray;
}
.close:hover {
	font-size: 28px;
	color: $gl-blue;
}
.text-info {
	color: #0E2752 !important;
}
.invalid-feedback {
	font-size: 16px;
}

.h3.pb-4 {
	border-bottom: 2px solid #05b;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
	outline-offset: -1rem;
}
.groups:nth-child(even) .h5 {
	color: #8e77aa;
}
.groups:nth-child(odd) .h5 {
	color: #379182;
}
#progress-chart .response {
	fill: $gl-blue;
	stroke: $gl-blue;
}
#progress-chart .error {
	fill: $gl-coral;
	stroke: $gl-coral;
}
select.form-control {
	appearance: menulist; /* remove once we have react bootstrap 5 */
}
.react-table thead th {
	border-right: solid 1px $gl-gray !important;
	border-left: solid 1px $gl-gray !important;
}
input[type=range] {
	width: 100%;
	max-width: 100%;
	margin-left: 0;
}

/* style datalist */
input[type=range] + datalist {
	display: block;
	margin-top: -4px;
}
input[type=range] + datalist option {
	display: inline-block;
	width: calc((100% - 12px) / (4));
	text-align: center;
}
input[type=range] + datalist option:first-child {
	width: calc((100% - 12px) / ((4) * 2) + 6px);
	text-align: left;
}
input[type=range] + datalist option:last-child {
	width: calc((100% - 12px) / ((4) * 2) + 6px);
	text-align: right;
}
@use 'variables';
$gl-blue: variables.$gl-blue;
$gl-coral: variables.$gl-coral;
$gl-green: variables.$gl-green;

#records {
	.DateRangePicker {
		white-space: nowrap;
		z-index: 2;
	}
	.DateInput {
		border-radius: 7px;
	}
	.DateRangePickerInput {
		border-radius: 7px;
	}
	.DateInput_input {
		border-radius: 5px;
	}
	.dot {
		height: 10px;
		width: 10px;
		border-radius: 50%;
		display: inline-block;
	}
	.dot.red {
		background-color: $gl-coral;
	}
	.dot.blue {
		background-color: $gl-blue;
	}
	.goal-legend {
		border-right: solid 2px $gl-green;
	}
	.react-table-col  {
		transition: 0.5s;
	}
	.closed .react-table-col {
		width: 0 !important;
		opacity: 0;
		transition: 0.6s;
	}
	.goal-cell {
		border-radius: 5px;
	}
	.goal-row, tr:not(.goal-row) .goal-cell {
		background-color: $gl-green !important;
		color: white;
	}
	.goal-row td {
		color: white;
	}
	.animate-mid {
		transition: 0.6s;
	}
	#raw-records {
		.form-control { 
			margin-left: -15px;
		}
		.react-table .table-responsive{
			max-height: 75vh;
		}
	}
	.react-table .table thead th:first-child {
		border-top-left-radius: 0 !important;
	}
	.react-table .table thead th:last-child {
		border-top-right-radius: 0 !important;
	}
	.social-networking-bar {
		display: none !important;
	}
	.react-table .table-responsive{
		max-height: 75vh;
	}
	#records-student-roster td:first-child {
		padding-left: 40px;
		position: relative;
	}
	.fidelity-warning td:first-child:before{
		content: '';
		background: url('../content/images/warning.svg');
		background-size:cover;
		position:absolute;
		width:18px;
		height:18px;
		top: calc(50% - 9px); /* vertically center based off 18px height */
		left:10px;
	}
	.records-chart {
		border-top-right-radius: 0 !important;
		width: calc(100% - 160px);
	}
}
#progress-chart {
	border-color: $gl-blue;
	border-top-right-radius: 0 !important;
}
#progress-chart .response {
	fill: $gl-blue;
	stroke: $gl-blue;
}
#progress-chart .error {
	fill: $gl-coral;
	stroke: $gl-coral;
}
.point.response, .line.response {
	fill: $gl-blue;
	stroke: $gl-blue;
}
.point.error, .line.error {
	fill: $gl-coral;
	stroke: $gl-coral;
}
#printer-button {
	font-size: 20px;
	color: $gl-blue;
	border: solid 1px;
	padding-right: 0.35rem;
	padding-left: 0.35rem;
	border-radius: 5px;
	border-width: 2px;
}
#printer-button:hover { 
	box-shadow: 0 2px 2px rgba($gl-blue,0.2);
	transition: 0.1s;
}

#printable-charts {
	display: none;
	margin-left: 40px;
	margin-bottom: 60px;
}
#printable-charts > h2 {
	margin-top: 15px;
	padding-top: 20px;
	margin-bottom: -15px;
	padding-bottom: 0px;
}
#printable-charts > svg {
	margin-bottom: -40px;
}
.subject-tab {
	width: 40px;
	height: 50px;
	border: solid 1px gray;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-left: none;
	display: flex;
}
.subject-tab.selected {
	box-shadow: -2px 1px white;
	border-color: $gl-blue;
}
.subject-tab img {
	height: 60%;
	max-width: 85%;
	margin: auto;
}
.fixed-bottom .subject-tab {
	width: 100%;
	border-radius: 0;
}
.fixed-bottom.Reading .subject-tab.selected {
	background-color: $gl-blue;
}
.fixed-bottom.Math .subject-tab.selected {
	background-color: $gl-coral;
}
.fixed-bottom .subject-tab img {
	max-width: 50%;
}
.infoBox {
	max-width: 200px;
	height: 120px;
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 12px;
	text-align: center;
	padding: 0.85rem;
	margin: auto;
}
#left-arrow, #right-arrow {
	cursor: pointer;
}
.filterByInstructor {
	width: 48%; 
	height: 24px;
	position: absolute;
	top: 1.5rem;
	z-index: 1;
	.form-control {
		height: 2.3rem;
	}
}
.selectedStudent {
	background-color: rgba(#CCD2DB,0.75);
}
/* hacky way to align elements with built in search bar */
@media (min-width: 768px) {
	#records-student-roster .react-table .form-control { 
		min-width: 120%;
		margin-left: -20%; /* react table search is too small for search on small widths - temp solution */
	}
	#records-student-roster .react-table>.d-flex { 
		padding-right: 10px;
	}
}

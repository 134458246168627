@use 'variables';
$gl-blue: variables.$gl-blue;
$gl-coral: variables.$gl-coral;
$font-reg: variables.$font-color-reg;

#subscribe {
	p {
		color: $font-reg;
	}
	#form-subscribe .price-card ul li:before {
		margin-left: -1.3rem;
	}
	.question-mark::after
	{
		font-family: "Glyphicons Halflings";
		padding-left: 5px;
		content: "\e085"; /* glyphicon-question-sign (hex value)*/
		color: #C0C0C0;
	}

	.title-question-mark
	{
		font-size: 20px;
		padding-left: 10px;
		padding-bottom: 3px;
	}

	.check-mark::before
	{
		font-family: "Glyphicons Halflings";
		padding-right: 7px;
		padding-left: 15px;
		font-size: 18px;

		content: "\e013"; /* glyphicon-ok (hex value)*/
		color: green;
	}

	.no-feature::before
	{
		font-family: "Glyphicons Halflings";
		padding-right: 7px;
		padding-left: 15px;
		font-size: 18px;

		content: "\e014"; /* glyphicon-remove (hex value)*/
		color: red;
	}

	.card-option
	{
		border-style: solid;
		border-width: 1px;
		border-color: #ccc;
		margin-bottom: 20px;
	}

	.ribbon
	{
		pointer-events: none;
		position: absolute;
		right: 12px;
		top: -5px;
		z-index: 1;
		overflow: hidden;
		width: 75px; height: 75px;
		text-align: right;
	}

	.ribbon span
	{
		font-size: 10px;
		font-weight: bold;
		color: #FFF;
		text-transform: uppercase;
		text-align: center;
		line-height: 20px;
		transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		width: 100px;
		display: block;
		background: #79A70A;
		background: linear-gradient(#F79E05 0%, #8F5408 100%);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
		top: 19px; right: -21px;
	}
	.ribbon span::before
	{
		content: "";
		position: absolute; left: 0px; top: 100%;
		z-index: -1;
		border-left: 3px solid #8F5408;
		border-right: 3px solid transparent;
		border-bottom: 3px solid transparent;
		border-top: 3px solid #8F5408;
	}
	.ribbon span::after
	{
		content: "";
		position: absolute; right: 0px; top: 100%;
		z-index: -1;
		border-left: 3px solid transparent;
		border-right: 3px solid #8F5408;
		border-bottom: 3px solid transparent;
		border-top: 3px solid #8F5408;
	}

	.price-card {
		max-width: 500px;
		border-radius: 21px;
		box-shadow: 5px 10px 30px -15px rgba(0, 85, 187, 0.5);
		padding-bottom:15px;
		padding-top: 15px;
		z-index: 2;
	}
	.price-card.blue {
		border: solid 2px $gl-blue;
	}
	.price-card.coral {
		border: solid 2px $gl-coral;
	}
	#form-subscribe {
		position: relative;
	}
	#form-subscribe .price-card ul {
		list-style-type: none;
	}
	#form-subscribe .price-card ul li:before {
		content:"\2714\0020";
	}
	.verification-card
	{
		margin-top: 20px;
	}

	/* The switch - the box around the slider */
	.switch
	{
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	}

	/* Hide default HTML checkbox */
	.switch input
	{
		opacity: 0;
		width: 0;
		height: 0;
	}

	/* The slider */
	.slider
	{
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: .4s;
		transition: .4s;
		}

	.slider:before
	{
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
		}

	input:checked + .slider:before
	{
		-webkit-transform: translateX(26px);
		-ms-transform: translateX(26px);
		transform: translateX(26px);
	}

		/* Rounded sliders */
		.slider.round
		{
		border-radius: 34px;
		}

	.slider.round:before
	{
		border-radius: 50%;
	}

	.row.display-flex
	{
		display: flex;
		flex-wrap: wrap;
		position: relative;
		min-height: 100%;
	}

	.row.display-flex > [class*='col-']
	{
		flex-grow: 1;
	}

	.annual-label
	{
		margin-top: 0px;
	}
	.price {
		padding-right: -15px !important;
		padding-left:-15px !important;
		flex-direction: row;
	}
	.price>span {
		font-size: 14px;
		color: gray;
	}
	.price>strong {
		font-size: 27px !important;
	}

	.dolphin-pricing {
		margin-bottom: -50vh;
		margin-right: -12%;
		max-height:320px;
		animation: bob 5s infinite ease-in-out;
		z-index: 3;
	}
	.fish-left {
		width: 30%;
		align-self: start;
	}
	.fish-right {
		width: 40%;
		align-self: right;
		margin-bottom: -9rem;
	}
	.water-surface {
		width: 100vw;
		background-repeat: no-repeat;
		padding-top: 10px;
		z-index: -1;
		background-image: url("../content/images/surface_wave.svg");
		background-size: contain;
	}
	.waves {
		width: 100%;
		height: 10vh;
		margin-top:-6vh;
		min-height: 90px;
		box-shadow:0 5px 6px 2px #9ACFFF2A,0 10px 10px 1px #9ACFFF1C,0 20px 12px 1px #9ACFFF1F;
		z-index: -1;
	}
	.bubble {
		position: absolute;
		border-radius: 50%;
		background-color: rgb(#9aceff,0.15);
		box-shadow: 0 5px 15px rgba($gl-blue, 0), inset 0px 10px 21px 8px rgb(255,255,255);
		opacity: 0;
		height: 200px;
		width: 200px;
		z-index: 1;
	}
	.bubble:after {
		background: radial-gradient(ellipse at center,  rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 70%);
		border-radius: 50%;
		box-shadow: inset 0 20px 20px -15px rgb(255, 255, 255), 0px 12px 3px -15px rgba(102, 198, 254,0.7), 0px 18px 5px -15px rgb(255, 255, 255,0.4);
		content: "";
		height: 180px;
		left: 10px;
		position: absolute;
		width: 180px;
	}
	@media (min-width: 768px)
	{
		.subscribe-card-bottom
		{
			position: absolute;
			bottom: 0px;
			width: 100%;
		}

		.training-card
		{
			margin-top: 20px;
		}

		.verification-card
		{
			margin-left: 30px;
		}
	}
	@media (max-width: 576px) {
		.dolphin-pricing {
			margin-bottom: -21vh;
			height: 120px;
		}
		.price-card {
			margin: 10px;
		}
		.fish-right {
			width: 40%;
			align-self: right;
			margin-bottom: -1rem;
		}
		#form-subscribe .price-card ul li:before {
			margin-left: -1.5rem;
		}
	}
	/* KEYFRAMES */
	@keyframes bob {
		0% {
				margin-top:0;
		}
		50% {
			margin-top: -20px;
		}
		100% {
				margin-top:0;
		}
	}
	@keyframes animateBubble {
		0% {
				top: 1500px;
				opacity: 1;
		}
		99% {
			opacity: 1;
		}
		100% {
				top: -100%;
				opacity: 0;
		}
	}
	@keyframes sideWays {
			0% {
					margin-left:0px;
			}
			100% {
					margin-left:50px;
			}
	}
	@keyframes move-forever {
		0% {
		transform: translate3d(-90px,0,0);
		}
		100% {
			transform: translate3d(85px,0,0);
		}
	}
		/* ANIMATIONS */
	.parallax > use {
		animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
	}
	.parallax > use:nth-child(1) {
		animation-delay: -2s;
		animation-duration: 24s;
	}
	.parallax > use:nth-child(2) {
		animation-delay: -3s;
		animation-duration: 26s;
	}
	.parallax > use:nth-child(3) {
		animation-delay: -4s;
		animation-duration: 21s;
	}
	.parallax > use:nth-child(4) {
		animation-delay: -5s;
		animation-duration: 20s;
	}
	.x1 {
		animation: animateBubble 15s linear 1, sideWays 2s ease-in-out infinite alternate;
		left: 85%;
		top: 5%;
		transform: scale(0.2);
	}
	.x2 {
		animation: animateBubble 18s linear 1, sideWays 4s ease-in-out infinite alternate;
		left:25%;
		top: 80%;
		transform: scale(0.1);
	}
	.x3 {
		animation: animateBubble 11s linear 1, sideWays 2s ease-in-out infinite alternate;
		left: 8%;
		top: 40%;
		transform: scale(0.2);
	}
	.x4 {
		animation: animateBubble 12s linear 1, sideWays 3s ease-in-out infinite alternate;
		left: 20%;
		top: 0;
		transform: scale(0.2);
	}
	.x5 {
		animation: animateBubble 15s linear 1, sideWays 4s ease-in-out infinite alternate;
		left: 30%;
		top: 30%;
		transform: scale(0.1);
	}
	.x6 {
		animation: animateBubble 13s linear 1, sideWays 2s ease-in-out infinite alternate;
		left: 45%;
		top: 0;
		transform: scale(0.3);
	}
	.x7 {
		animation: animateBubble 15s linear 1, sideWays 2s ease-in-out infinite alternate;
		left: 65%;
		top: 70%;
		transform: scale(0.2);
	}
	.x8 {
		animation: animateBubble 10s linear 1, sideWays 3s ease-in-out infinite alternate;
		left: 3%;
		top: 10%;
		transform: scale(0.3);
	}
}
